<template>
<footer class="footer mt-auto">
  <div class="full-container container">
    <div class="row">
      <div class="col-md-2 logo">
        <div class="logo-pic">
          <img src="@/assets/images/logo-footer.svg" alt="">
        </div>
      </div>
      <div class="col-md-5 features">
        <div class="item-group">
          <div class="row group-block">
            <div class="item about">
              <div class="title" @click="about = !about"><span>關於酒訊</span><i class="fas fa-chevron-down fa-xs"></i></div>
              <ul :class="{active: about}">
                <li><router-link to="/about">關於我們</router-link></li>
                <li><router-link to="/cooperation">廣告合作</router-link></li>
                <li><a href="mailto:service@ch-9.net">人才招募</a></li>
                <li><a href="mailto:service@ch-9.net">聯絡我們</a></li>
              </ul>
            </div>
            <div class="item others">
              <div class="title"  @click="others = !others"><span>其他服務</span><i class="fas fa-chevron-down fa-xs"></i></div>
              <ul :class="{active: others}">
                <li><router-link to="/privacy">服務條款與隱私權聲明</router-link></li>
                <li><router-link to="/subscription">雜誌訂閱</router-link></li>
              </ul>
            </div>
            <div class="item subscription">
              <div class="title">關注我們</div>
              <ul class="d-flex media-icons">
                <li><a href="https://www.facebook.com/WSD123" target="_blank"><i class="fab fa-facebook-square fa-2x"></i></a></li>
                <li><a href="https://lin.ee/xuVds1R"><i class="fab fa-line fa-2x"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-5">
        <div class="title">訂閱電子報</div>
        <input type="email" class="subscription-input" placeholder="請輸入您的電子信箱">
        <button type="button" class="subscription-btn">訂閱</button>
      </div> -->
    </div>
    <div class="bottom-footer-info">
      <span>客服專線 02-25095777</span>
      <span>客服時間 週一~五 09：00 ~ 18：00(國定假日休息)</span>
      <span>2020-2023 © </span>
      <span>
      網站管理：英屬開曼群島李奧摩根股份有限公司。統一編號83566853<br/>
      授權公司：酒訊文化事業股份有限公司。統一編號28170820
      </span>
    </div>
  </div>
  <BoxRightTool />
  <BoxWarning />
</footer>
</template>

<script setup>
import BoxWarning from '@/components/box/Warning.vue'
import BoxRightTool from '@/components/box/RightTool.vue'
import { ref } from 'vue'
const about = ref(false)
const others = ref(false)
</script>

<style lang="scss" scoped>
footer {
  color: #A6A8AC;
  background: #2A313C;
  padding: 15px 0 5px;
  z-index: 50;
  position: relative;
  right: 0;
  left: 0;
  bottom: 0;
  .logo-pic {
    max-width: 165px;
    width: 100%;
    @include media-breakpoint-down(sm){
      margin: 0 auto 30px;
      max-width: 130px;
    }
  }
  .title {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 15px;
    @include media-breakpoint-down(sm){
      font-size: 16px;
      font-weight: 400;
    }
  }
  .item-group {
    .group-block {
      @include media-breakpoint-down(sm){
        display: block;
      }
    }
    padding: 0 15px;
    .item {
      padding-left: 15px;
      @include media-breakpoint-down(sm){
        padding-left: 0px;
      }
      &:last-child {
        .title{
          @include media-breakpoint-down(sm){
            border-bottom: none;
            padding-bottom: 0px;
          }
        }
      }
      .title {
        @include media-breakpoint-down(sm){
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #fff;
          padding-bottom: 8px;
        }
        i {
          display: none;
          @include media-breakpoint-down(sm){
            display: block;
          }
          &.active {
            @include media-breakpoint-down(sm){
                transform: rotate(180deg);
            }
          }
        }
      }
      ul {
        &.active {
          display: block;
        }
        @include media-breakpoint-down(sm){
          display: none;
        }
      }
      a {
        display: inline-block;
        margin-bottom: 5px;
      }
    }
    .about {
      flex: 1 0 auto;
    }
    .others {
      flex: 2 0 auto;
    }
    .subscription {
      flex: 1 0 auto;
    }
    .line {
      color: #A6A8AC;
    }
  }
  .media-icons li {
    margin-right: 10px;
  }
  .subscription-input {
    border: none;
    padding: 10px;
    width: 70%;
    margin-right: 5px;
  }
  .subscription-btn {
    border: none;
    padding: 10px 0;
    text-align: center;
    width: calc(30% - 10px);
    background: #A6A8AC;
    color: #0C121C;
  }
  .bottom-footer-info {
    font-size: 12px;
    color: #fff;
    text-align: right;
    @include media-breakpoint-down(sm){
      margin-top: 20px;
      text-align: center;
    }
    span + span {
      margin-left: 5px;
      @include media-breakpoint-down(sm){
        display: block;
        margin-left: 0px;
      }
    }
  }
}
</style>
